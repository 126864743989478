<template>
  <div class="wrapper flex-grow-1 d-flex flex-column">
    <v-overlay
      :value="saving"
      absolute
    >
      <div
        class="fill-height fill-width"
      >
        <o-loader
          :height="300"
          :width="300"
          color="white"
        />
      </div>
    </v-overlay>

    <v-row
      v-if="crossroad"
      class="flex-grow-1"
    >
      <v-col
        cols="6"
        class="flex-grow-1"
      >
        <transition
          name="fade"
          mode="out-in"
        >
          <o-map-traffic-lights-state
            v-if="ready"
            :traffic-lights="trafficLights"
            :paths="paths"
            :mode="crossroad && crossroad.stationState ? crossroad.stationState.mode : null"
            class="elevation-3"
          />
        </transition>
      </v-col>

      <v-col cols="6">
        <v-row>
          <v-col>
            <v-icon class="mr-1">
              mdi-information
            </v-icon>
            {{ $t('messages.views.admin.components.dashboard.crossroadModal.window.information.stationWatcherState') }}
            <v-divider />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-simple-table>
              <tbody>
                <tr>
                  <td>
                    <v-icon>mdi-clock-outline</v-icon>
                  </td>
                  <td>
                    {{ $t('messages.views.admin.components.dashboard.crossroadModal.window.information.realMode') }} :
                  </td>
                  <td>
                    <o-chip-station-disconnected
                      v-if="!crossroad.station.connected"
                      small
                    />
                    <o-chip-station-synchronization
                      v-else-if="crossroad.station.synchronization !== 'SYNCHRONIZATION_DONE'"
                      :synchronization="crossroad.station.synchronization"
                    />
                    <o-chip-crossroad-state
                      v-else
                      small
                      :out-of-control="!crossroad.inControl"
                      :mode="getCrossroadRealMode(crossroad)"
                      :traffic-light-plan="getCrossroadRealTrafficLightPlan(crossroad)"
                    />
                  </td>
                  <td>
                    <template v-if="!crossroad.station.connected">
                      <template v-if="crossroad.station.connectedChangedAt">
                        {{ moment.utc(crossroad.station.connectedChangedAt).local().format('L LTS') }}
                      </template>
                    </template>
                    <template v-else-if="crossroad.station.synchronization === 'SYNCHRONIZATION_DONE'">
                      <template v-if="!crossroad.inControl">
                        <template v-if="crossroad.inControlChangedAt">
                          {{ moment.utc(crossroad.inControlChangedAt).local().format('L LTS') }}
                        </template>
                      </template>
                      <template v-else-if="realSince">
                        {{ moment.utc(realSince).local().format('L LTS') }}
                      </template>
                    </template>
                  </td>
                </tr>

                <tr v-if="areCrossroadStatesDifferent(crossroad) || crossroad.station.synchronization !== 'SYNCHRONIZATION_DONE' || !crossroad.station.connected || !crossroad.inControl">
                  <td>
                    <v-icon>mdi-timer-outline</v-icon>
                  </td>
                  <td>
                    {{ $t('messages.views.admin.components.dashboard.crossroadModal.window.information.theoreticalMode') }} :
                  </td>
                  <td>
                    <o-chip-crossroad-state
                      small
                      :mode="crossroad.userState && crossroad.userState.mode"
                      :traffic-light-plan="crossroad.userState !== null && crossroad.userState.trafficLightPlan !== null ? crossroad.userState.trafficLightPlan.number : null"
                      theoretical
                    />
                  </td>
                  <td>
                    <template v-if="crossroad.userState !== null && crossroad.userState.since">
                      {{ moment.utc(crossroad.userState.since).local().format('L LTS') }}
                    </template>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <o-form
              ref="stationWatcherStateForm"
              v-confirm="submitStationWatcherState"
              confirm-event="submit"
              confirm-title="messages.views.admin.components.dashboard.crossroadModal.window.information.confirm.title"
              confirm-text="messages.views.admin.components.dashboard.crossroadModal.window.information.confirm.text"
              :confirm-action="getSelectedMode"
              :confirm-color="getSelectedModeColor"
              confirm-security-check
              :enable-on-start="crossroad.userState === null || crossroad.userState === 'MODE_UNKNOWN'"
            >
              <v-row>
                <v-col>
                  <v-icon class="mr-1">
                    mdi-pencil
                  </v-icon>
                  {{ $t('messages.views.admin.components.dashboard.crossroadModal.window.information.changeMode') }}
                  <v-divider />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-simple-table>
                    <tbody>
                      <tr>
                        <td>
                          <v-icon>mdi-clock-outline</v-icon>
                        </td>
                        <td class="text-no-wrap">
                          {{ $t('labels.crossroad.mode') }} :
                        </td>
                        <td>
                          <o-crossroad-mode-select
                            v-model="selectedMode"
                            :full-width="false"
                          />
                        </td>
                      </tr>

                      <tr v-if="selectedMode === 'MODE_TRAFFIC_LIGHT_PLAN'">
                        <td>
                          <v-icon>mdi-clock-outline</v-icon>
                        </td>
                        <td class="text-no-wrap">
                          {{ $t('labels.crossroad.trafficLightPlan') }} :
                        </td>
                        <td>
                          <o-traffic-light-plan-select
                            v-model="selectedTrafficLightPlan"
                            :traffic-light-plans="trafficLightPlans"
                            label="labels.crossroad.trafficLightPlan"
                            hide-label
                          />
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="d-flex justify-center">
                  <o-submit />
                </v-col>
              </v-row>
            </o-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { getColorFromMode } from '@utils/crossroad';
  import WebSocketTopic from '@api/web-socket-topic';
  import webSocketClient from '@mixin/web-socket-client';

  const moment = require('moment');

  export default {
    mixins: [webSocketClient],

    props: {
      stationId: {
        type: [String, Number],
        required: true,
      },
      crossroadId: {
        type: [String, Number],
        required: true,
      },
    },

    apollo: {
      crossroad: {
        query: require('@gql/views/admin/components/dashboard/crossroad-modal/window/information/getCrossroad.gql'),
        variables: function () {
          return {
            id: this.crossroadId,
          };
        },
        client: 'stations',
        fetchPolicy: 'no-cache',
        result (res, key) {
          this.loading = res.loading;
        },
        update: function (data) {
          let trafficLightPlan;
          if (data.getCrossroad.userState !== null) {
            this.selectedMode = data.getCrossroad.userState.mode;
            trafficLightPlan = data.getCrossroad.userState.trafficLightPlan;
          } else if (data.getCrossroad.stationWatcherState !== null) {
            this.selectedMode = data.getCrossroad.stationWatcherState.mode;
            trafficLightPlan = data.getCrossroad.stationWatcherState.trafficLightPlan;
          } else if (data.getCrossroad.stationState !== null) {
            this.selectedMode = data.getCrossroad.stationState.mode;
            trafficLightPlan = data.getCrossroad.stationState.trafficLightPlan;
          }

          if (this.selectedMode === 'MODE_TRAFFIC_LIGHT_PLAN') {
            this.selectedTrafficLightPlan = trafficLightPlan !== null ? trafficLightPlan.id : null;
          }

          this.trafficLightPlans = data.getCrossroad.trafficLightPlans;
          this.trafficLights = data.getCrossroad.trafficLights.filter(trafficLight => !!trafficLight.location);
          for (const trafficLight of this.trafficLights) {
            if (trafficLight.stateSince) {
              trafficLight.stateSince = moment.utc(trafficLight.stateSince).toDate();
            }
          }

          return data.getCrossroad;
        },
      },
    },

    data: function () {
      return {
        ready: false,
        model: true,
        loading: true,
        saving: false,
        selectedMode: null,
        selectedTrafficLightPlan: null,
        trafficLightPlans: [],
        trafficLights: [],
      };
    },

    computed: {
      realSince () {
        const stationState = this.crossroad.stationState;
        const stationWatcherState = this.crossroad.stationWatcherState;

        if (stationState === null) {
          return null;
        }

        if (stationWatcherState && stationWatcherState.since !== null) {
          if (stationState.since !== null) {
            return moment.utc(stationState.since).unix() > moment.utc(stationWatcherState.since).unix()
              ? stationState.since
              : stationWatcherState.since
            ;
          } else {
            return stationWatcherState.since;
          }
        } else if (!stationWatcherState || stationWatcherState.since === null) {
          return stationState.since;
        } else {
          return null;
        }
      },

      paths () {
        if (!this.crossroad) {
          return [];
        }

        const paths = [];
        for (const entryPoint of this.crossroad.entryPoints) {
          let active = false;
          for (const trafficLight of this.crossroad.trafficLights) {
            if (trafficLight.entryPoints.includes(entryPoint.id) && trafficLight.state) {
              active = true;
              break;
            }
          }

          for (const path of entryPoint.paths) {
            paths.push({
              ...path,
              active: active,
            });
          }
        }

        return paths;
      },
    },

    watch: {
      model: function (newValue, oldValue) {
        if (!newValue) {
          this.$emit('close');
        }
      },
    },

    mounted () {
      this.webSocketSubscribe(WebSocketTopic.CROSSROAD_IN_CONTROL, this.onCrossroadInControl);
      this.webSocketSubscribe(WebSocketTopic.CROSSROAD_OUT_OF_CONTROL, this.onCrossroadOutOfControl);
      this.webSocketSubscribe(WebSocketTopic.CROSSROAD_USER_STATE_CHANGED, this.onCrossroadUserStateChanged);
      this.webSocketSubscribe(WebSocketTopic.CROSSROAD_STATION_WATCHER_STATE_CHANGED, this.onCrossroadStationWatcherStateChanged);
      this.webSocketSubscribe(WebSocketTopic.CROSSROAD_STATION_STATE_CHANGED, this.onCrossroadStationStateChanged);
      this.webSocketSubscribe(WebSocketTopic.STATION_SYNCHRONIZED, this.onStationSynchronized);
      this.webSocketSubscribe(WebSocketTopic.STATION_UNSYNCHRONIZED, this.onStationUnsynchronized);
      this.webSocketSubscribe(WebSocketTopic.STATION_CONNECTED, this.onStationConnected);
      this.webSocketSubscribe(WebSocketTopic.STATION_DISCONNECTED, this.onStationDisconnected);
      this.webSocketSubscribe(WebSocketTopic.CROSSROAD_TRAFFIC_LIGHTS_STATE_CHANGED, this.onCrossroadTrafficLightsStateChanged, {
        crossroadId: this.crossroadId,
      });
      window.setInterval(() => { this.ready = true; }, 300);
    },

    methods: {
      onCrossroadTrafficLightsStateChanged (payload) {
        if (this.crossroad) {
          for (const trafficLight of this.crossroad.trafficLights) {
            const state = payload.states.find(tl => tl.number === trafficLight.number);
            if (state) {
              trafficLight.state = state.state;
            }
          }
        }
      },

      getSecondsSinceTrafficLightState (trafficLight) {
        return moment().unix() - moment(trafficLight.stateSince).unix();
      },

      submitStationWatcherState (password) {
        let mutation;
        const input = {
          crossroadId: this.crossroadId,
        };
        switch (this.selectedMode) {
          case 'MODE_SMARTWAY':
            mutation = require('@gql/mutations/crossroad/switchCrossroadUserStateToSmartwayMode.gql');
            break;
          case 'MODE_TIMELINE':
            mutation = require('@gql/mutations/crossroad/switchCrossroadUserStateToTimelineMode.gql');
            break;
          case 'MODE_TRAFFIC_LIGHT_PLAN':
            mutation = require('@gql/mutations/crossroad/switchCrossroadUserStateToTrafficLightPlanMode.gql');
            input.trafficLightPlanId = this.selectedTrafficLightPlan;
            break;
          case 'MODE_BLINKING':
            mutation = require('@gql/mutations/crossroad/switchCrossroadUserStateToBlinkingMode.gql');
            break;
          case 'MODE_EXTINCTION':
            mutation = require('@gql/mutations/crossroad/switchCrossroadUserStateToExtinctionMode.gql');
            break;
        }
        this.saving = true;
        this.$securityCheck({
          mutation: mutation,
          client: 'stations',
          variables: {
            input: input,
          },
        }, password).then((result) => {
          this.$flash('messages.views.admin.components.dashboard.crossroadModal.window.information.changedWithSuccess', 'success');
        }).catch((e) => {
          if (e.message !== 'INVALID_PASSWORD') {
            this.$flash(null);
          }
        }).finally(() => {
          this.saving = false;
        });
      },

      areCrossroadStatesDifferent (crossroad) {
        if (
          (!crossroad.userState || !crossroad.userState.mode) &&
          (!crossroad.stationState || !crossroad.stationState)
        ) {
          return false;
        }

        if (!crossroad.userState) {
          return !!crossroad.stationState;
        }

        if (!crossroad.stationState) {
          return !!crossroad.userState;
        }

        switch (crossroad.userState.mode) {
          case 'MODE_SMARTWAY':
            if (!crossroad.stationWatcherState || crossroad.stationWatcherState.mode !== 'MODE_SMARTWAY') {
              return true;
            }

            return (!crossroad.stationState || crossroad.stationState.mode !== 'MODE_TRAFFIC_LIGHT_PLAN');
          case 'MODE_TIMELINE':
            if (!crossroad.stationWatcherState || crossroad.stationWatcherState.mode !== 'MODE_TIMELINE') {
              return true;
            }

            return (!crossroad.stationState || crossroad.stationState.mode !== 'MODE_TRAFFIC_LIGHT_PLAN');
          case 'MODE_TRAFFIC_LIGHT_PLAN':
            if (!crossroad.stationState || crossroad.stationState.mode !== 'MODE_TRAFFIC_LIGHT_PLAN') {
              return true;
            }

            if (crossroad.stationState.trafficLightPlan === null) {
              return crossroad.userState.trafficLightPlan !== null;
            }
            if (crossroad.userState.trafficLightPlan === null) {
              return true;
            }

            return crossroad.stationState.trafficLightPlan.number !== crossroad.userState.trafficLightPlan.number;
          case 'MODE_BLINKING':
          case 'MODE_EXTINCTION':
            return !crossroad.stationState || crossroad.stationState.mode !== crossroad.userState.mode;
        }
      },

      getCrossroadRealMode (crossroad) {
        if (crossroad.stationState && crossroad.stationState.mode) {
          switch (crossroad.stationState.mode) {
            case 'MODE_TRAFFIC_LIGHT_PLAN':
              if (crossroad.stationWatcherState && crossroad.stationWatcherState.mode) {
                switch (crossroad.stationWatcherState.mode) {
                  case 'MODE_TRAFFIC_LIGHT_PLAN':
                    return 'MODE_TRAFFIC_LIGHT_PLAN';
                  case 'MODE_SMARTWAY':
                    return 'MODE_SMARTWAY';
                  case 'MODE_TIMELINE':
                    return 'MODE_TIMELINE';
                  default:
                    return null;
                }
              } else {
                return 'MODE_TRAFFIC_LIGHT_PLAN';
              }
            default:
              return crossroad.stationState.mode;
          }
        } else {
          return null;
        }
      },

      getCrossroadRealTrafficLightPlan (crossroad) {
        if (crossroad.stationState && crossroad.stationState.trafficLightPlan) {
          return crossroad.stationState.trafficLightPlan.number;
        } else {
          return null;
        }
      },

      getSelectedMode () {
        if (this.selectedMode === 'MODE_TRAFFIC_LIGHT_PLAN') {
          if (this.selectedTrafficLightPlan) {
            const trafficLightPlan = this.crossroad.trafficLightPlans.find(trafficLightPlan => trafficLightPlan.id === this.selectedTrafficLightPlan);

            return this.$t('messages.views.admin.components.dashboard.crossroadModal.window.information.trafficLightPlan', { number: trafficLightPlan.number });
          } else {
            return this.$t('messages.views.admin.components.dashboard.crossroadModal.window.information.noTrafficLightPlan');
          }
        }

        return this.$t('constants.crossroadState.mode.' + this.selectedMode);
      },

      getSelectedModeColor () {
        return getColorFromMode(this.selectedMode);
      },

      onCrossroadInControl (payload) {
        if (payload.crossroadId.toString() === this.crossroadId.toString()) {
          this.crossroad.inControl = true;
          this.crossroad.inControlChangedAt = new Date();
        }
      },

      onCrossroadOutOfControl (payload) {
        if (payload.crossroadId.toString() === this.crossroadId.toString()) {
          this.crossroad.inControl = false;
          this.crossroad.inControlChangedAt = new Date();
        }
      },

      onCrossroadUserStateChanged (payload) {
        if (payload.crossroadId.toString() === this.crossroadId.toString()) {
          this.crossroad.userState = {
            mode: payload.mode,
            since: new Date(),
            trafficLightPlan: payload.trafficLightPlanNumber !== undefined && payload.trafficLightPlanNumber !== null
              ? this.crossroad.trafficLightPlans.find(trafficLightPlan => trafficLightPlan.number === payload.trafficLightPlanNumber)
              : null
            ,
          };
        }
      },

      onCrossroadStationWatcherStateChanged (payload) {
        if (payload.crossroadId.toString() === this.crossroadId.toString()) {
          this.crossroad.stationWatcherState = {
            mode: payload.mode,
            since: new Date(),
            trafficLightPlan: payload.trafficLightPlanNumber !== undefined && payload.trafficLightPlanNumber !== null
              ? this.crossroad.trafficLightPlans.find(trafficLightPlan => trafficLightPlan.number === payload.trafficLightPlanNumber)
              : null
            ,
          };
        }
      },

      onCrossroadStationStateChanged (payload) {
        if (payload.crossroadId.toString() === this.crossroadId.toString()) {
          this.crossroad.stationState = {
            mode: payload.mode,
            since: new Date(),
            trafficLightPlan: payload.trafficLightPlanNumber !== undefined && payload.trafficLightPlanNumber !== null
              ? this.crossroad.trafficLightPlans.find(trafficLightPlan => trafficLightPlan.number === payload.trafficLightPlanNumber)
              : null
            ,
          };
        }
      },

      onStationSynchronized (payload) {
        if (payload.stationId.toString() === this.stationId.toString()) {
          this.crossroad.station.synchronization = 'SYNCHRONIZATION_DONE';
        }
      },

      onStationUnsynchronized (payload) {
        if (payload.stationId.toString() === this.stationId.toString()) {
          this.crossroad.station.synchronization = 'SYNCHRONIZATION_PENDING';
        }
      },

      onStationConnected (payload) {
        if (payload.stationId.toString() === this.stationId.toString()) {
          this.crossroad.station.connected = true;
          this.crossroad.station.connectedChangedAt = new Date();
        }
      },

      onStationDisconnected (payload) {
        if (payload.stationId.toString() === this.stationId.toString()) {
          this.crossroad.station.connected = false;
          this.crossroad.station.connectedChangedAt = new Date();
        }
      },
    },
  };
</script>

<style lang="sass" scoped>
  .wrapper
    min-height: 300px
</style>
