import { render, staticRenderFns } from "./Information.vue?vue&type=template&id=0c5528d3&scoped=true&"
import script from "./Information.vue?vue&type=script&lang=js&"
export * from "./Information.vue?vue&type=script&lang=js&"
import style0 from "./Information.vue?vue&type=style&index=0&id=0c5528d3&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../.yarn/__virtual__/vue-loader-virtual-8057611e5a/0/cache/vue-loader-npm-15.9.8-438f4d0ff0-ca4c99b261.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c5528d3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../.yarn/__virtual__/vuetify-loader-virtual-ed9205d01e/0/cache/vuetify-loader-npm-1.7.3-f1d1b39131-81a3169810.zip/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VCol,VDivider,VIcon,VOverlay,VRow,VSimpleTable})
